import React, { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"
import Slider from "react-slick"
import Ativo1 from "../images/1-Tri-01.png"
import Ativo2 from "../images/1-Tri-02.png"
import Ativo3 from "../images/1-Tri-03.png"
import Ativo4 from "../images/1-Tri-04.png"
import Ativo5 from "../images/1-Tri-05.png"
import Ativo6 from "../images/1-Tri-06.png"

import Ativo7 from "../images/2-Tri-01.png"
import Ativo8 from "../images/2-Tri-02.png"
import Ativo9 from "../images/2-Tri-03.png"
import Ativo10 from "../images/2-Tri-04.png"
import Ativo11 from "../images/2-Tri-05.png"
import Ativo12 from "../images/2-Tri-06.png"
import Ativo13 from "../images/2-Tri-07.png"
import Ativo14 from "../images/2-Tri-08.png"
import Ativo15 from "../images/2-Tri-09.png"
import Ativo16 from "../images/2-Tri-10.png"

import Ativo17 from "../images/3-Tri-01.png"
import Ativo18 from "../images/3-Tri-02.png"
import Ativo19 from "../images/3-Tri-03.png"
import Ativo20 from "../images/3-Tri-04.png"
import Ativo21 from "../images/3-Tri-05.png"
import Ativo22 from "../images/3-Tri-06.png"
import Ativo23 from "../images/3-Tri-07.png"
import Ativo24 from "../images/3-Tri-08.png"
import Ativo25 from "../images/3-Tri-09.png"
import Ativo26 from "../images/3-Tri-10.png"
import Ativo27 from "../images/3-Tri-11.png"
import Ativo28 from "../images/3-Tri-12.png"
import Ativo29 from "../images/3-Tri-13.png"
import Ativo30 from "../images/3-Tri-14.png"

const TheiaGravidezSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [updateCount, setUpdateCount] = useState(0)
  const [indexActive, setIndexActive] = useState(0)
  const sliderRef = useRef(Slider)
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    swipeToSlide: true,
    centerPadding: '0px',
    variableWidth: true,
    className: "slider variable-width",
    afterChange: () =>
    setUpdateCount(updateCount + 1),
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  }
  const handleOnClick = (index: number) => {
    sliderRef.current.slickGoTo(index);
    if (index === 0) {
      setIndexActive(0)
    } else if (index === 6) {
      setIndexActive(6)
    } else {
      setIndexActive(16)
    }
  }

  const changeTab = (e: any) => {
    if (e.target.value < 6) {
      setIndexActive(0)
    } else if (e.target.value < 16) {
      setIndexActive(6)
    } else if (e.target.value >= 16) {
      setIndexActive(16)
    }
  }
  return (
    <>
      <div className="flex items-center justify-around lg:justify-center mt-8 lg:mt-12">
        <button
          onClick={() => handleOnClick(0)}
          className={`text-dsTextSmal text-textSecondary ${indexActive === 0 ? 'jornada-tab-active' : ''}`}
        >
          1º Trimestre
        </button>
        <button
          onClick={() => handleOnClick(6)}
          className={`text-dsTextSmal text-textSecondary lg:mx-12 ${indexActive === 6 ? 'jornada-tab-active' : ''}`}
        >
          2º Trimestre
        </button>
        <button
          onClick={() => handleOnClick(16)}
          className={`text-dsTextSmal text-textSecondary ${indexActive === 16 ? 'jornada-tab-active' : ''}`}
        >
          3º Trimestre
        </button>
      </div>
      <Slider {...settings} ref={sliderRef} className="overflow-hidden">
        <div>
          <div className="">
            <img src={Ativo1} alt="ilustra primeiro trimestre" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo2} alt="Nesta semana você deve passar por uma consulta obstétrica presencial." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo3} alt="Nesta semana é legal passar pelo primeiro check-in psicológico." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo4} alt="Hora de passar com a nutri." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo5} alt="Semana de consulta com a obstetra." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo6} alt="Marque uma consulta com seu preparador físico, pra começar uma rotina de exercícios na gestação." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo7} alt="ilustra segundo trimestre" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo8} alt="Nesta semana você passa em consulta obstétrica." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo9} alt="Nova consulta com sua obstetra." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo10} alt="Hora de passar  com a nutri neste trimestre." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo11} alt="Indicamos que você passe com seu preparador físico, pra alinhar sua rotina de exercícios." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo12} alt="Sua obstetra quer ver você nesta semana." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo13} alt="Hora do check-in psicológico do 2º trimestre." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo14} alt="Se optou por incluir a doula na sua jornada, é a hora de marcar a sua primeira sessão." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo15} alt="Sua obstetra quer ver você."/>
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo16} alt=" Esta é uma ótima semana pra fazer sua consulta pré-natal pediátrica." />
          </div>
        </div>

        <div>
          <div className="">
            <img src={Ativo17} alt="ilustra terceiro trimestre" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo18} alt="Passe nesta semana com a nutri." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo19} alt="Marque uma consulta com sua especialista pessoal para falar sobre o seu plano de parto." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo20} alt="Semana de consulta com a obstetra." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo21} alt="Vamos ver como o preparador físico pode te auxiliar neste trimestre? Passe em uma consulta nesta semana." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo22} alt="Vai fazer o parto com a  Theia? Agora é um bom momento para conhecer a enfermeira obstetra que vai te acompanhar no parto." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo23} alt="Vamos bater aquele papo com a psicóloga no check-in do 3º trimestre?" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo24} alt="Semana de consulta com a obstetra e hora de passar pela fisioterapêuta pélvica. " />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo25} alt="Uma ótima semana pra sua consulta pré-natal online com a consultora de amamentação." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo26} alt="Sua obstetra quer te ver essa semana." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo27} alt="Semana de consulta com a Enfermeira Obstétrica, pra ela tirar suas dúvidas sobre o trabalho de parto e primeiros cuidados com o bebê. Ah, e lembre de fazer sua sessão de fisio pélvica." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo28} alt="Tem mais uma consulta com a obstetra e outra, com a fisio pélvica. Aproveite e fale com a sua especialista pessoal." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo29} alt="Sua obstetra quer te ver essa semana." />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo30} alt="Verifique com sua obstetra sobre controle de vitalidade e monitoramento fetal a partir dessa semana." />
          </div>
        </div>
      </Slider>
      <input
        onChange={(e) => {
          sliderRef.current.slickGoTo(e.target.value)
          changeTab(e)
        }}
        value={slideIndex}
        type="range"
        min={0}
        max={29}
        className="jornada-range block lg:hidden mx-auto mt-12"
      />
      <input
        onChange={(e) => {
          sliderRef.current.slickGoTo(e.target.value)
          changeTab(e)
        }}
        value={slideIndex}
        type="range"
        min={0}
        max={25}
        className="jornada-range hidden lg:block  mx-auto mt-12"
      />
      </>
  )
}

export default TheiaGravidezSlider