import React, { useEffect, useState } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import './especialistas.scss'
import './theia-pacotes.scss'
import trackEvent from "../analyticsHelpers"
import RegularButton from '../components/atoms/buttons/RegularButton'
import ImgTopo from '../images/topo-theigravidez.png'
import { goToApp } from "../utils"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import FirstCel from '../images/theia-gravidez-print1.png'
import SecondCel from '../images/theia-gravidez-print2.png'
import ThirdCel from '../images/theia-gravidez-print3.png'
import FontIcon from "../components/atoms/fontIcon/FontIcon"
import TheiaGravidezSlider from "../components/theiaGravidezSlider"

const TheiaGravidez = () => {
 
  useEffect(() => {
    trackEvent("sitenovo-theia-gravidez-visualizou-tela")
  }, [])
  
  return (
    
    <Layout>
      <GatsbySeo
        title='Theia | Gravidez'
        description='Desenhamos um programa para você ter uma gravidez com mais apoio, cuidado e saúde'
        language='pt-br'
        canonical='https://blog.theia.com.br/theia-gravidez'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/theia-gravidez',
          title: 'Theia | Gravidez',
          description: 'Desenhamos um programa para você ter uma gravidez com mais apoio, cuidado e saúde',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Gravidez'
        }, {
          name: 'dc:description',
          content: 'Desenhamos um programa para você ter uma gravidez com mais apoio, cuidado e saúde'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'Desenhamos um programa para você ter uma gravidez com mais apoio, cuidado e saúde',
        },
         {
          name: 'keywords',
          content:  'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <section className="specialists-1 section-1 grid-wrapper-section items-center lg:items-start theia-gravidez-topo">
        <div className="text-specialist-content">
          <h1 className="text-dsTitle2 lg:text-dsTitle1 text-primary main-title mb-6 text-center lg:text-left">
            Theia Gravidez
          </h1>
          <p className="text-center lg:text-left text-dsTextMedium text-textSecondary mb-10 lg:mb-12">
            Desenhamos um programa para você ter uma gravidez com mais apoio, cuidado e saúde
          </p>
          <div className="flex justify-between lg:justify-start">
            <RegularButton
              onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-theia-gravidez-clicou-botao-comece-seu-programa')}
              label="Comece seu programa"
              extraClass="flex-1 btn-topo-especialistas"
            />
            <AnchorLink
              to="/theia-gravidez#jornada-theia"
              onAnchorLinkClick={() => trackEvent("sitenovo-theia-gravidez-clicou-botao-saiba-mais")}
              className="btn-text-topo-especialistas"
            >
              Saiba mais
            </AnchorLink>
          </div>
        </div>
        <div className="img-specialist-content self-end internas">
          <img src={ImgTopo} alt="" style={{ width: '592px' }} />
        </div>
      </section>
      <section className="jornada-slider" id="jornada-theia">
        <h2 id="section-especialistas-collapse" className="mt-8 lg:mt-20 specialists-title text-primary text-titleMedium lg:text-dsTitle2 text-center">
          Entenda como funciona a jornada Theia Gravidez
        </h2>
         <TheiaGravidezSlider />
      </section>
      <section className="bg-bgCanvas section-3 grid-wrapper-section servicos-phones items-center lg:items-start theia-pacotes">
        <div className="item1 text-center">
          <h2 className="text-titleMedium lg:text-dsTitle2 text-primary mt-12 mx-auto">
            Redefinimos a experiência do cuidado na gravidez com mais apoio, respeito e autoconfiança
          </h2>
        </div>
        <div className="item2 mt-0 lg:mt-12">
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col items-center flex-1 lg:mt-16 mt-8">
              <FontIcon iconType="icon-Property-2Calendar" color="#582CC7" fontSize="34px" />
              <p className="p-cel text-textPrimary text-dsTextSmal text-center mt-4 mb-6">Nossa plataforma te indica o que fazer a cada semana </p>
              <img src={FirstCel} alt="" />
            </div>
            <div className="flex flex-col items-center flex-1 my-12 lg:my-0 mx-0 lg:mx-12">
              <FontIcon iconType="icon-Property-2Star" color="#582CC7" fontSize="34px" />
              <p className="p-cel text-textPrimary text-dsTextSmal text-center mt-4 mb-6">Te conecta a um time integrado de profissionais</p>
              <img src={SecondCel} alt="" />
            </div>
            <div className="flex flex-col items-center flex-1 lg:mt-16 mb-16 lg:mb-0">
              <FontIcon iconType="icon-Property-2Journey" color="#582CC7" fontSize="34px" />
              <p className="p-cel text-textPrimary text-dsTextSmal text-center mt-4 mb-6">E mostra conteúdos relevantes para o seu momento</p>
              <img src={ThirdCel} alt="" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TheiaGravidez